<!-- inputTree -->
<template>
  <div class="inputTree-container">
    <div v-if="showTree" class="treeDiv" ref="suoShuMenTree">
      <el-tree
        :data="treeData"
        :props="defaultProps"
        :expand-on-click-node="false"
        accordion
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用

export default {
  name: "inputTree",
  components: {},
  props: {
    treeData: { type: Array, required: true }
  },
  data() {
    return {
      showTree: false,
      defaultProps: {
        children: "childrenList",
        label: "name"
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 输入框里的树形
    deptogglePanel(event) {
      event || (event = window.event);
      event.stopPropagation
        ? event.stopPropagation()
        : (event.cancelBubble = true);
      // this.showTree = true;
      this.showTree ? this.dephide() : this.depshow();
    },
    depshow() {
      this.showTree = true;
      document.addEventListener("click", this.dephidePanel, false);
    },
    dephide() {
      this.showTree = false;
      document.addEventListener("click", this.dephidePanel, false);
    },
    dephidePanel(e) {
      if (
        this.$refs.suoShuMenTree &&
        !this.$refs.suoShuMenTree.contains(e.target)
      ) {
        this.dephide();
      }
    },
    handleNodeClick(data) {
      console.log(data);
    this.$emit('childVal',data.name)
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.treeDiv {
  width: 215px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-left: 51px;
  overflow: auto;
}
</style>
